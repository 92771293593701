@import url('https://fonts.googleapis.com/css?family=Yantramanav:regular,bold,italic&subset=latin,latin-ext');

.vertical {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Yantramanav'
}

.padded {
  padding: 1.5%;
}